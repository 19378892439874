.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 100%;
  padding: 1rem 2rem 0 2rem;
  width: 100%;
  z-index: 35;
  margin-bottom: 0px;
  box-shadow: 22px 422px 222px rgba(0, 0, 0, 0.1), 2 22px 22px rgba(0, 0, 0, 0.08);
}

.calendar-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.navbar-container {
  width: 100%;
  padding: 1rem 2rem;
  align-content: center;
  height: 100%;
  z-index: 35;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.start-button{
  display: hidden;
}
.logo {
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Mate", serif;
  font-weight: 500;
  font-style: normal;
  font-family: "Open Sans", sans-serif;
  letter-spacing: .5cap;
  text-decoration: none;
  position: relative;
}
.red{
  color:red;
}
.blue{
  color: rgb(0, 119, 255);
}
.green{
  color:green;
}
.llogo {
  height: 100%;
  margin-right: 6px;

}

.logo-text {
  display: flex;
  flex-direction: column;
}

.market {
  font-weight: normal;
  font-size: 0.9rem;
  margin-top: 5px;
  color: #666;
  letter-spacing: .35cap;

}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.nav-links li a {
  font-style: normal;
  font-family: "Open Sans", sans-serif;
  letter-spacing: .5cap;
  color: #000000;
  text-decoration: none;
  font-size: 1rem;

}


.nav-links li a:hover {
  color: #3054e4;
}

.nav-toggle {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}
.start-button {
  display:none;
  background-color: #ffffff;
  color: #0056b3;
  border: 1px solid #0056b3;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;

}

.start-button:hover {
  background-color: #2538b8;
  color: #ffffff;
}
/* src/components/css/Navbar.css */

.calendly-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.calendly-container {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  position: relative;
}

.close-calendly {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
  color: #000;
}

.calendly-button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.calendly-button:hover {
  background: #0056b3;
}
li .calendly-link{
  padding:8px 12px;
   border: 1px solid #0056b3;
  border-radius:4px;
  color:#0056b3 !important;
font-weight: 500;
letter-spacing: .2cap !important;
}
@media (max-width: 800px) {
  .hero-text-mobile h2{
    margin-top: 10;
  }
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
  }
.logo-text{
  display: none;
}
.start-button{
  display: flex;
}
.start-button.open{
  display: none;
}
  .logo {
    justify-content: center;
    text-align: center;
    font-size: medium;
    margin-left:12%;

    margin-top: 0px;
  }
.logo.open{
  display: none;
}
  .nav-links.open {
    display: flex;
justify-content: center;
text-align: center;
margin-right: 2rem; /* Adjust margin to account for nav-toggle displacement */
padding:0;
  }

  .nav-toggle {
    display: flex;
    height: 100%;
  }

  .digi {
    font-size: small;
  }

  .navbar {
    flex-direction: row;
    margin-bottom: 0px;
    padding-bottom: 8px;

  }
  .navbar-container{
padding: 0;
    margin:0;
    display: flex;
  }
  .navbar-container.open{
    padding: 0;
        margin:0;
        display: flex;
        align-items: flex-start;
      }


  .nav-links li {
    margin: 10px 0;
  }
}
