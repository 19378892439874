.pricing-table {
    width: 100%;
    margin: 20px auto;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .pricing-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .pricing-table th,
  .pricing-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .pricing-table th {
    background-color: #446ab6;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .pricing-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .pricing-table tr:hover {
    background-color: #ddd;
  }
  
  .pricing-table th {
    background-color: #4b5969;
    color: white;
  }
  
  .pricing-table td {
    font-size: 14px;
  }
  
  .pricing-table td:first-child {
    text-align: left;
    font-weight: bold;
  }
  .cta-buton {
    text-align: center;
    margin: 20px;
    margin-top: 15px;
    display: inline-block;
    padding: 0.5rem .9rem;
    font-size: 1rem;
    color: #ffffff;
    background: #394965;    border-radius: 5px;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    letter-spacing: .1cap;
    margin-bottom: 20px;
  }
  
  .cta-buton:hover {
    background: #4e699b;
    ;
  }
  .business-size-dropdown {
    text-align: center;
    margin-bottom: 20px;
}

.business-size-dropdown select {
    padding: 10px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: border-color 0.3s;
}

.business-size-dropdown select:hover {
    border-color: #0044cc;
}
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  background-color: #0044cc;
  color: #fff;
  padding: 10px;
}

tbody td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
