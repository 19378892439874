.side-widget-button {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 120px;
    background-color: red;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .side-widget-button:hover {
    background-color: darkred;
  }
  
  .side-widget-text {
    transform: rotate(-90deg);
    color: white;
    font-weight: bold;
    text-align: center;
    line-height: normal;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-left: 11px;
  }
  @media (max-width: 768px) {
    .side-widget-button {
      display: none;
    }
  }  