/* Services.css */
.services-container {
  padding: 2rem;
  text-align: center;
  overflow: hidden;
  background-color: #ffffff; /* Light blue background */
}
.services-container h1{
  color: #2a2a2a; /* Light blue heading color */

}
.services-grid {
  margin: 5%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.service-item {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #d3e0ea; /* Light blue border */
  border-radius: 5px;
  text-align: center;
  width: 30%;
  margin-bottom: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service-item img {
  max-width: 100%;
  height: auto;
  border: none; /* Remove border */
  margin-bottom: 1rem;
}

.service-item h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #2f3842; /* Light blue heading color */
}

.service-item p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.service-item .btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #4991de; /* Light blue button color */
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.service-item .btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

@media (max-width: 480px) {
  .services-container {
      padding: 1rem;
  }

  .service-item {
      width: 100%;
  }

  .service-item h3 {
      font-size: 1.2rem;
  }

  .service-item p {
      font-size: 0.9rem;
  }

  .service-item .btn {
      padding: 0.5rem;
      font-size: 0.9rem;
  }
}
