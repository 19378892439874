.local-searches-charts.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
    margin-bottom: 20px;
  }
  
  .grid-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin: 10px;
    flex: 1;
    min-width: 150px;
  }
  
  .countup-container {
    text-align: center;
    margin-top: 10px;
  }
  
  .countup-container p {
    margin-top: 5px;
    font-size: 1em;
    line-height: 1.5;
    text-align: center;
  }
  