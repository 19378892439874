.how-is-this-different {
  align-items: center;
    text-align: center;
    padding: 6rem 3rem;
    
  }
  
  .how-is-this-different h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature {
    flex: 1 ;
    margin: 1rem;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
  }
  
  .icon {
    color: #ff4b5c;
    margin-bottom: 1rem;
  }
  
  .feature p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .feature {
      flex: 1 1 100%;
      margin: 1rem 0;
    }
  
    .how-is-this-different h2 {
      font-size: 1.5rem;
    }
  
    .feature p {
      font-size: 0.9rem;
    }
  }
  